<template>
  <div class="general">
      <smallbutton toplabel="Picture Requirements" @click="Add()"/>
    <!-- ACCT NOTE MODAL -->
      <actionmodalwide ref="mymodal" > 
        <template v-slot:body>
                <div class="rightjustified">
                    <smallbutton @click="close()" toplabel='X' buttonclass="buttonclose" />
                </div>
                <label class="titlelabel">Update Picture Requirements</label>

                <div class="vertFlex margintop" >
                    
                    <div v-if="showEdit">
                        <froala :tag="'textarea'" :config="config" v-model="photoRequirements"  ></froala>
                    </div>
                    
                    <div class="fview" v-if="!showEdit" >
                        <froalaView v-model="htmlWithSample" />
                    </div>

                    <div class="horizFlexStretch">
                        <div>
                            <smallbutton  @click="toggleSample()" :toplabel="ToggleText" />
                        </div>
                        <div>
                            <smallbutton  @click="saveNew()" toplabel="Save" />
                            <smallbutton  @click="cancelupdate()" toplabel="Cancel" />
                        </div>
                    </div>
                </div>

                <div>
                    <label class="responsemessage">{{message}}</label>
               </div>
       
        </template>
      </actionmodalwide>
      
  </div>
</template>

<script>
// import VueFroala from 'vue-froala-wysiwyg';
import FroalaEditor from 'froala-editor'


import smallbutton from '../controls/button.vue'
import actionmodalwide from '../controls/actionmodalwide.vue'
import datamethods from '../../datamethods.js'

export default {
    name: 'picturerequirements',
    components:{
        smallbutton,
        actionmodalwide,
      
    },
    mixins:[datamethods],
    data () {
        return {
            config: {
                height:420,
                key:window.FROALA_KEY,
                events: {
                    'froalaEditor.initialized': function () {
                        console.log('initialized')
                    },
  
                },
                toolbarButtons:[['undo','redo','selectAll'],
                ['bold','italic','underline','subscript','superscript','fontFamily','fontSize','textColor','backgroundColor','clearFormatting'],
                ['alignLeft','alignCenter','alignRight','alignJustify'],
                ['insertLink','emoticons','specialCharacters','insertHR'],['print']],
            
            },
            /* config: {
                height:420,
                events: {
                    'froalaEditor.initialized': function () {
                        console.log('initialized')
                    },
  
                },
                toolbarButtons:[['undo','redo','selectAll'],
                ['bold','italic','underline','subscript','superscript','fontFamily','fontSize','textColor','backgroundColor','clearFormatting'],
                ['alignLeft','alignCenter','alignRight','alignJustify'],
                ['insertLink','insertImage','insertVideo','emoticons','specialCharacters','insertHR'],['print']],
            
            }, */
            message:'',

            
            baseButtons:[['undo','redo','selectAll'],
                ['bold','italic','underline','subscript','superscript','fontFamily','fontSize','textColor','backgroundColor','clearFormatting'],
                ['alignLeft','alignCenter','alignRight','alignJustify'],
                ['insertLink','insertImage','insertVideo','emoticons','specialCharacters','insertHR'],['print']],
            addedButtons:[],
            showEdit:true,
            photoRequirements:''
        }
    },
    computed:{
        ToggleText(){
            if(this.showEdit){
                return "Show Example"
            }else{
                return "Hide Example"
            }
        },
        htmlWithSample(){
            var out = this.photoRequirements
            
            return out
        },
    },
    methods:{
        close(){
            this.$emit('refresh')
            this.$refs.mymodal.closeModal()
        },
        toggleSample(){
            this.showEdit=!this.showEdit
        },
        
        async Add(){
            this.getPhotoReq()
            this.$refs.mymodal.openModal()
        },
        addToolBarInsert(bname,btext){
            FroalaEditor.DefineIcon('insert', {NAME: 'plus', SVG_KEY: 'add'});
            FroalaEditor.RegisterCommand(bname, {
                title: bname,
                focus: true,
                undo: true,
                refreshAfterCallback: true,
                callback: function () {
                this.html.insert(btext);
                }
            });
            var newButton = [bname]
            console.log('what im adding ' + bname + ' ' +  newButton + ' ' + btext)
            this.config.toolbarButtons.push(newButton)

        },
        async getPhotoReq(){
            
            let req = {
                locationId:-1
            }
            //call api
            
            var response = await this.callAPI(req,'photoreq')

            //handle response
            if(response.Successful){
            this.photoRequirements = response.StringResponse
            }else{
            this.message = response.Message
            }
            this.pleaseWait=false;

        },
        update(){
            this.inUpdate=true;
            this.showEdit=true;
        },
        cancelupdate(){
            this.$refs.mymodal.closeModal()
        },
        async saveNew(){
        
            let req = {
                locationId:-1,
                requirementsText:this.photoRequirements
            }
            //call api
            
            var response = await this.callAPI(req,'savephotoreq')

            //handle response
            if(response.Successful){
                this.$refs.mymodal.closeModal()
            //this.photoRequirements = response.StringResponse
            }else{
            this.message = response.Message
            }
            this.pleaseWait=false;

 
        },
        AddToolbarButtons(){
           // this.addToolBarInsert("insert this!!","[this]")

            for(let index = 0; index < this.selectedResult.tokenList.length; ++index){
                const t = this.selectedResult.tokenList[index]
                this.addToolBarInsert(t.name,t.tagtext)
            } 
             
            
        }
    }
}
</script>

<style>
.general{
    font-size:medium;
}
.smalltext{
    font-size:small;
}
.margintop{
    margin-top:20px;
}
.fview{
    background-color: white;
    padding:5px;
    text-align: left;
}
textarea{
    visibility: collapse;
    height: 0px;
}
</style>