<template>
    <div class="pmtdiv">
        <smallbutton :toplabel="name" @click="start()" v-if="showButton" :buttonclass="buttonClass"/>
        <linkbutton :toplabel="name" @click="start()" v-if="!showButton"/>
        <!--  MODAL -->
          <actionmodalwide ref="mymodal"> 
            <template v-slot:body>
                <form ref="balform">
                    
                    <label class="titlelabel">{{name}}</label>
                    <br/>
                    <div class="horizFlex">
                        <label class="filterlbl">Filter by</label>
                        <input type="date" v-model="smsHistory.startDate"  class='filterselect' @change="getsmslist()"/>  
                        <label class="filterlbl">to</label>
                        <input type="date" v-model="smsHistory.endDate"  class='filterselect' @change="getsmslist()"/> 
                        <input type="checkbox" v-model="smsHistory.includeSuccess" class="filtercheckbox" @change="getsmslist()"/>
                            <label class="filterchecklabel">Include Success</label>
                        
                        <input type="checkbox" v-model="smsHistory.includeFailure" class="filtercheckbox" @change="getsmslist()"/>
                             <label class="filterchecklabel">Include Failure</label>
                        
                        
                    </div>
                    <div class="recordclassheaders">
                                <label class="text subtitle ticketfield" for>Date</label>
                                <label class="text subtitle ticketfield" for>Name</label>
                                <label class="text subtitle ticketfield leftjustify" for>Phone Number</label>
                                <label class="text subtitle ticketfield leftjustify" for>Message</label>
                                <!-- <label class="text subtitle ticketfield rightjustify" for>Success</label> -->
                                <label class="text subtitle ticketfield leftjustify" for>Error Message</label>
                             
                            </div>
                    <div class="scrollbox2">
                        <div class="" v-for="t in smsList" :key="t.id" :value="t.id" >
                            <div :class="recordclass(t)">
                                <label class="text subtitle ticketfield" for>{{toDate(t.sentDate)}}</label>
                                <label class="text subtitle ticketfield" for>{{t.name}}</label>
                                <label class="text subtitle ticketfield leftjustify" for>{{t.number}}</label>
                                <label class="text subtitle ticketfield leftjustify" for>{{t.messageBody}}</label>
                                <!-- <label class="text subtitle ticketfield rightjustify" for>{{t.success}}</label> -->
                                <label class="text subtitle ticketfield leftjustify" for>{{t.errorMessage}}</label>
                             
                            </div>
                            
                        </div>
                    </div>
                               
                   
                    <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                    <div>
                        <label class="responsemessage">{{responseMessage}}</label>
                    </div>
                   
                    
                </form>
                
                
            </template>
          </actionmodalwide>
        </div>
    </template>
    
    <script>
    import {mapFields} from 'vuex-map-fields'
    import format from '../../format.js'
    import smallbutton from '../controls/button.vue'
    import linkbutton from '../controls/linkbutton.vue'
    import actionmodalwide from '../controls/actionmodalwide.vue'
    import formentry from '../controls/entry.vue'
    import datamethods from '../../datamethods.js'
    import confirm from '../actions/confirm.vue'
    import securityhelper from '../../services/securityhelper.js'

    export default {
        name:'smshistory',
        components:{
            smallbutton,
            actionmodalwide,
            formentry,
            linkbutton,
            confirm,
           
        },
        props:{
           
            showButton:{
                type:Boolean,
                default:true
            },
            name:{
                type:String,
                default:"Text History"
            },
            defaultAmount:{
                type:Number,
            },
            numberDataLabel:{
                type:String,
                default:''
            },
            buttonClass:{
                type:String,
                default:'button buttonprimary bottom10'
            }
            
        },
        mixins:[format, datamethods,securityhelper],
        directives:{
            print
        },
        data(){
            return{
                smsList:[],
                smsHistory:{
                    startDate:null,
                    endDate:null,
                    includeSuccess:true,
                    includeFailure:true
                }
            }
        },
        computed:{
            ...mapFields([
                'responseMessage',
                'pleaseWait',

     
            ]),
    
        },
        methods:{
            toDate(dt){
                return this.$options.filters.formatDate(dt)
            },
   
            recordclass(t){
                if(t.success){return "recordclass green"}
                else{return "recordclass red"}
            },
            start(){
                
                this.smsHistory.endDate = this.$options.filters.formatDateForInputToday()
                let today =new Date();
                var date1 =new Date().setDate(today.getDate()-7);
                this.smsHistory.startDate = this.$options.filters.formatDateForInput(date1)
                this.smsHistory.includeSuccess=true
                this.smsHistory.includeFailure=true
                this.getsmslist()
    
            },
            async getsmslist(){
                
              
                //call api
               // this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(this.smsHistory,'smshistory')
                //handle response
            
                if(response.Successful){
                    this.smsList=response.smsItemList
                    this.$refs.mymodal.openModal()
                   
                }else{
                this.responseMessage = response.Message
                }
                this.pleaseWait=false;
                        
            },
                
        }
    }
    
    </script>
    
    <style>
    
    .recordclass{
        display:grid;
        grid-template-columns: 1fr 1fr 1fr 3fr 3fr ;
        
        margin:5px;
    }
    .green{
        border:1px solid green;
    }
    .red{
        border:1px solid red;
    }
    .recordclassheaders{
        display:grid;
        grid-template-columns: 1fr 1fr 1fr 3fr 3fr ;
      
        margin:5px;
    }
    .leftjustify{
        text-align: left;
    }
    .rightjustify{
        text-align: right;
    }

.scrollbox2{
  height:520px;
  overflow-y: scroll;
  overflow-x:hidden;
}
.filterlbl{
    margin:5px;
}
.filtercheckbox{
    margin-bottom:10px;
}
.filterchecklabel{
    margin:5px;
}
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    