<template>
<div>
    <smallbutton toplabel="Add Email Recipient" @click="Add()"/>
    <!--  MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form id="emailform" ref="emailform" @submit.prevent>
                <label class="titlelabel">Add Email Recipient</label>
                <formentry label="Name" isrequired='True' v-model="NewEmail.Name" />
                <formentry label="Email Address" isrequired='True' v-model="NewEmail.EmailAddress"  />

                <smallbutton  @click="save()" toplabel="Save" />
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Cancel"/>
                <div>
                    <label class="responsemessage">{{responseMessage}}</label>
               </div>
            </form>
            
        </template>
      </actionmodal>
    </div>
</template>

<script>
import {ReportHTTP} from '../../repository'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import actionmodal from '../controls/actionmodal.vue'
import formentry from '../controls/entry.vue'
export default {
    name:'addrecipient',
    components:{
        smallbutton,
        actionmodal,
        formentry
    },
    props:{

        
    },
    mixins:[format],
    data(){
        return{
            
            responseMessage:'',
            pleaseWait:false,

            NewEmail:{
                Name:'',
                EmailAddress:''
            },

        }
    },
    computed:{

    },
    methods:{
        beforeAPI(){
            this.pleaseWait=true
            this.responseMessage=''
        },
        Add(){
             this.NewEmail={
                Name:'',
                EmailAddress:''
            },
            this.$refs.mymodal.openModal()
        },
       async save(){
            if(this.$refs.emailform.checkValidity()){
                let req = {
                    emailRecipient:this.NewEmail
                }
                this.pleaseWait=true;
                ReportHTTP.post('addemailrecipient',req)
                .then(response=>{
                    this.$refs.mymodal.closeModal()
                    this.$emit('refresh')
                })
                .catch(e=>{
                    this.pleaseWait=false;
                    if(e.message=='Network Error'){
                        this.errorMessage = "Report Error: Cannot Reach API"
                    }
                    this.errorMessage = "Report Error: " + e.message;

                    console.log(e)
                    return e
                });
            }
        },
        
    }
}
</script>

<style>
.entryCheckLabel{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
}
.entryCheckLabelbold{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
    font-weight:bold;
}
</style>





























