import { render, staticRenderFns } from "./entrycolor.vue?vue&type=template&id=588bc918&"
import script from "./entrycolor.vue?vue&type=script&lang=js&"
export * from "./entrycolor.vue?vue&type=script&lang=js&"
import style0 from "./entrycolor.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./entrycolor.vue?vue&type=custom&index=0&blockType=img&%3Asrc=getIconFile()&class=icon&crossorigin=anonymous"
if (typeof block0 === 'function') block0(component)
import block1 from "./entrycolor.vue?vue&type=custom&index=1&blockType=entrycolor&label=Title%20Text%20Color&v-model=design.titleTextColor&%40selectcolor=selectcolor_title()"
if (typeof block1 === 'function') block1(component)
import block2 from "./entrycolor.vue?vue&type=custom&index=2&blockType=colorpicker&ref=cpicker_title&%40selectcolor=gotcolor_title"
if (typeof block2 === 'function') block2(component)

export default component.exports