<template>
<div>

    <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <div >
                <div class="mainvar">
                    <div>
                        <label class="titlelabel">Select Color from Logo</label>
                        <div>
                            <div  v-for="c in palette" :key="c.id">

                                <div class="colorbar" :style="getstyle(c)" @click="select(c)">
                                    <p class="colorlabel">{{c.hex}}</p>
                                </div>
                            
                            </div>
                            <label v-if="palette==null">No colors available</label>
                        </div>
                    </div>
                    <div>
                        <label class="titlelabel">Select Custom Color</label>
                        <div class="colorsel">
                            <chrome-picker v-model="colors" @input="picker"/>
                        </div>

                        <div class="colorbar" :style="getstyle(color_black)" @click="select(color_black)">
                            <p class="colorlabel_light">Black</p>
                        </div>
                        <div class="colorbarbordered" :style="getstyle(color_white)" @click="select(color_white)">
                            <p class="colorlabel">White</p>
                        </div>
                    </div>
                </div>
                

                <hr>

                <div >
                    <div class="colorsel">
                        <div class="selectedvar" v-if="selectedcolor!=''">
                            <label class="colorlabel" >Selected Color: </label>
                            <div class="colorchip" :style="getchipstyle()"></div>
                            <label class="colorlabel" >{{selectedcolor}}</label>
                        </div>
                    </div>
                    
                    <smallbutton @click="done()" toplabel='Save' buttonclass="button buttonhighlight" />
                    <smallbutton @click="cancel()" toplabel='Cancel'  />
                </div>
                
            </div>

        </template>
      </actionmodal>
</div>

 
</template>

<script>
import actionmodal from './actionmodal.vue'
import smallbutton from './button.vue'
import ColorThief from '../../../node_modules/colorthief/dist/color-thief.umd.js'
import {Chrome} from 'vue-color'
export default {
    name: 'colorpicker',
    components:{
        actionmodal,
        smallbutton,
        'chrome-picker':Chrome
    },
    props: {
        imgUrl:{
            type:String
        },
        
    },
    data() {
        return {
            selectedcolor:'',
            palette:null,
            colors:'',

            color_black:{
                0:0,
                1:0,
                2:0,
                hex:'#000000',
                selected:false
            },
            color_white:{
                0:255,
                1:255,
                2:255,
                hex:'#FFFFFF',
                selected:false
            }
        }
  },
  computed:{
      
  },
  methods:{
      picker(){
          this.selectedcolor=this.colors.hex
      },
    getstyle(c){
        var s = 'background-color: ' + c.hex
        return s
    },
    getchipstyle(){
    
        var s = 'background-color: ' + this.selectedcolor
        return s
    },
    select(c){
        this.selectedcolor=c.hex
    
    } ,
    getcolors(img){
        if(img==undefined){return}
      const colorThief = new ColorThief()
      this.palette = colorThief.getPalette(img)
      this.palette.forEach(myFunction3)
        function myFunction3(item, index, arr) {
            var a = arr[index]
            a.hex = ConvertRGBtoHex(a[0],a[1],a[2])
            a.selected=false

            function ColorToHex(color) {
                var hexadecimal = color.toString(16);
                return hexadecimal.length == 1 ? "0" + hexadecimal : hexadecimal;
            }

            function ConvertRGBtoHex(red, green, blue) {
                return "#" + ColorToHex(red) + ColorToHex(green) + ColorToHex(blue);
            }
        }  
    },
    openModal(){
        this.$refs.mymodal.openModal()
    },
    done(){
        console.log(this.selectedcolor)
        this.$emit('selectcolor',this.selectedcolor)
        this.$refs.mymodal.closeModal()
    },
    cancel(){
        this.$refs.mymodal.closeModal()
    }
  }
}
</script>

<style>
.mainvar{
    display:grid;
    grid-template-columns:1fr 1fr;
    grid-template-rows:auto;
}
.margin{
    margin:20px 0px 0px 0px;
}
.colorlabel{
    margin:10px;
}
.colorlabel_light{
    margin:10px;
    color:white;
}
.colorchip{
    height:30px;
    width:30px;
}
.colorbar{
    padding:.5px;
    margin:5px;
}
.colorbarbordered{
    padding:.5px;
    margin:5px;
    border:1px solid black;
}
.colorsel{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:5px;
    
}
.selectedvar{
    display:flex;
    flex-direction: row;

}
</style>