<template>
    <div class="general">
        <smallbutton toplabel="Text Templates" @click="Add()" />
      <!-- ACCT NOTE MODAL -->
        <actionmodal ref="mymodal" > 
          <template v-slot:body>
                  <div class="rightjustified">
                      <smallbutton @click="close()" toplabel='X' buttonclass="buttonclose" />
                  </div>
                  <label class="titlelabel">Update Text Template</label>
  
  
                  <div v-if="!inUpdate" class="margintop">
                      
                      <div v-for="result in lookupResults" :key="result.id" :value="result.id" >
                          <lookupresult :topLeft="result.name"      @click="select(result)"
                              :selected="result.id==selectedResultID" :inactive="!result.IsActive">
                      
                          </lookupresult>  
                      </div>
                      <div>
                        <smallbutton  @click="add()" toplabel="Add" />
                          <smallbutton  @click="update()" toplabel="Update" />
                          <!-- <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/> -->
                      </div>
                  </div>
  
                  <div class="vertFlex margintop" v-if="inUpdate">
                      <label class="titlelabel">{{selectedResult.name}}</label>
                      <entry label="Name" v-model="selectedResult.name" maxlength="100"/>
                      <!-- <entry label="Subject" v-model="selectedResult.subject" maxlength="100" /> -->
                      <!-- <entry label="Plain Text" v-model="selectedResult.plain"/> -->
                      <!-- <entry label="Text Message" v-model="selectedResult.sms" maxlength="160"/> -->
                      <label class="leftjustified margin5">Text Message</label>
                      <textarea class="smsarea"  v-model="selectedResult.sms" maxlength="160" rows="3"/>
                      <div>
                        <label class="responsemessage">{{charactersRemaining}} of 160 characters remaining</label>
                    </div>
  
                      <div >
                        
                          
                              <smallbutton  @click="saveNew()" toplabel="Save" />
                              <smallbutton  @click="cancelupdate()" toplabel="Cancel" />
                         
                      </div>
                  </div>
  
                  <div>
                      <label class="responsemessage">{{message}}</label>
                 </div>
         
          </template>
        </actionmodal>
        
    </div>
  </template>
  
  <script>
 
  import smallbutton from '../controls/button.vue'
  import actionmodal from '../controls/actionmodal.vue'
  import lookupviewlayout from '../layouts/lookupviewlayout.vue'
  import lookupresult from '../controls/lookupresult.vue'
  import entry from '../controls/entry.vue'
  import datamethods from '../../datamethods.js'
  
  export default {
      name: 'smstemplateadmin',
      components:{
          smallbutton,
          actionmodal,
          lookupviewlayout,
          lookupresult,
          entry
      },
      mixins:[datamethods],
      data () {
          return {
              
              message:'',
  
              selectedResultID:0,
              lookupResults:[],
              selectedResult:{
                  id:0,
                  name:'',
                  html:'',
                  plain:'',
                  sms:'',
                  subject:'',
                  IsActive:true,
                  tokenList:[]
              },
              
              inUpdate:false,
              showEdit:false,
  
          }
      },
      computed:{
          
          characters(){
            return this.selectedResult.sms.length
          },
          charactersRemaining(){
            return 160 - this.characters
          }
      },
      methods:{
          close(){
              this.$emit('refresh')
              this.$refs.mymodal.closeModal()
          },
          
          select(result){
              this.selectedResult=result;
              this.selectedResultID=result.id;
              
          },
          async Add(){
              this.getTemplates(true)
              
              
          },
          
          async getTemplates(fromadd=false){
              
              var response = await this.callAPI(null,'templates_sms')
              if(response.Successful){
                  console.log(response)
                  
                  this.lookupResults=response.MessageTemplateList
                  if(this.lookupResults.length>0){this.select(this.lookupResults[0])}
                 
                  if(fromadd){
                    this.$refs.mymodal.openModal()
                  }
                 
              }else{
                  //this.message = response.Message
              }
  
          },
          update(){
              this.inUpdate=true;
              this.showEdit=true;
          },
          add(){
            this.selectedResult={
                  id:0,
                  name:'',
                  html:'',
                  plain:'',
                  sms:'',
                  subject:'',
                  IsActive:true,
                  tokenList:[]
              }
            this.inUpdate=true;
              this.showEdit=true;
          },
          cancelupdate(){
              this.inUpdate=false;
          },
          async saveNew(){
              let req = {MessageTemplate: this.selectedResult}
              
              var response = await this.callAPI(req,'savetemplate_sms')
              if(response.Successful){
                  console.log(response)
                  this.getTemplates()
    
              }else{
                  //this.message = response.Message
              }
              this.inUpdate=false;
          },
          
      }
  }
  </script>
  
  <style >
  .general{
      font-size:medium;
  }
  .smalltext{
      font-size:small;
  }
  .margintop{
      margin-top:20px;
  }
  .fview{
      background-color: white;
      padding:5px;
      text-align: left;
  }
  .smsarea{
      
      height:50px;
    
      visibility: visible;
      margin:5px;
  }
  .margin5{
    margin:5px;
  }
  </style>