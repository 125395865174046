<template>
<div>
    <linkbutton toplabel="View Documents" @click="GetDocuments()" class="alertlink"/>
    <!-- ACCT NOTE MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form >
                <div class="history">
                        <label class="studentname" >Documents</label>
                        <div v-if="pleaseWait"><label class="pleasewait">Please wait...</label></div>
                        <div v-if="pleaseWaitSign"><label class="pleasewaitdoc">Please wait while we prepare your document...</label></div>
                        <div >
                            <div class="docDiv">
                                <div v-for="document in DocumentList" :key="document.id" :value="document.id" class="documentitems" >
                                    <img src="@/assets/check.png" class="docimg" v-if="document.fileDisplayName!=null"/>
                                    <div class="vertFlex col2" >
                                        <div class="horizFlex">
                                            <label :class="doctitleClass(document)" >{{document.typeName}}</label>
                                            <label class="required" v-if="document.required" >*</label>
                                        </div>
                                        <!-- <linkbutton :href="document.fileName" target="_blank" :toplabel="document.fileDisplayName"/> -->
                                        <a class="timetext" :href="document.fileName" target="_blank" v-if="document.canView">{{document.fileDisplayName }} </a>
                                        <a class="timetext" :href="document.fileName_Signature" target="_blank" v-if="showSignatureLink(document)">{{document.enteredName_Signature }}  Signature on  {{ document.signatureDate | formatDate}} </a>
                                    </div>
                                    
                                    <div class="vertFlex col3">
                                        <button class="docbutton" type="button"  @click="openDocImage(document)">
                                            <img src="@/assets/file-document-edit-outline.png" class="docimg2"/>
                                        </button>
                                        <confirm :showLink="trueParam" :showImageButton="falseParam" body='Do you want to remove this document?' title="Remove" @yes="deactivateDocument(document.id)" v-if="document.fileDisplayName!=null && document.canDeactivate"/> 

                                        <linkbutton :class="signlinkClass(document)"  toplabel="sign" @click="openSignModal(document.fileName, document.id,document.typeName)" v-if="okToSign(document) && useExternalSign==false"/>

                                        <linkbutton toplabel="Fill and Sign" @click="externalSign(document.id, document.externalSignatureTemplate)" v-if="okToSign(document) && useExternalSign"/>
                                    </div>      
                                </div>
                            </div>
                            
                            <label class="required"  >* required</label>
                            <imagealldoc ref="imgdoc"   :allowFileSelect="allowFileSelect" :allowCapture="allowCapture" @saveimg="saveimg_document"  :showImage='showImagesInCapture' />
                            <signaturemodal ref="signdoc" @savesign="savesign_document" />
                            
                            <confirm ref="confirmDeactivate" :showLink="falseParam" :showImageButton="falseParam" body='You already have a document of this type. Do you want to remove the previous copy of the document?' title="Remove" @yes="deactivateDocument(docIdToDeactivate)" @no="GetDocuments" /> 
                        </div>
   
                </div>
                <div>
                    <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                </div>
               
          </form>
        </template>
      </actionmodal>
      <GlobalEvents v-if="listenersConnected" target="window" @focus="GetDocuments"/>
    </div>
</template>

<script>
import datamethods from '../../datamethods.js'
import smallbutton from '../controls/button.vue'
import actionmodal from '../controls/actionmodal.vue'
import linkbutton from '../controls/linkbutton.vue'
import format from '../../format.js'
import imagealldoc from '../controls/ImageAllDoc.vue'
import signaturemodal from '../controls/signaturemodal.vue'
import confirm from '../actions/confirm.vue'
export default {
    name:'documentadmin',
    components:{
        smallbutton,
        actionmodal,
        linkbutton,
        imagealldoc,
        signaturemodal,
        confirm
    },
    props:{
        record:{
            type:Object,
            
        },
        
    },
    mixins:[datamethods, format],
    data(){
        return{
             listenersConnected:false,
            imageBasePath:'',
            DocumentList:[],
            showImagesInCapture:false,
            allowFileSelectIfNoCapture:true,
            allowCapture:true,
            allowFileSelect:true,
            trueParam:true,
            falseParam:false,
            pleaseWait:false,
            pleaseWaitSign:false,
            docIdToDeactivate:0
        }
    },
    computed:{
        
       useExternalSign(){
        
         if(window.USE_EXTERNAL_SIGNING==undefined){
                return false
            }else{return window.USE_EXTERNAL_SIGNING}
       }
    },
    methods:{
        okToSign(document){
            return document.fileDisplayName!=null && document.canSign && document.fileName_Signature==null
        },
        showSignatureLink(document){
            return document.fileName_Signature!=null && document.fileName_Signature!="externalsignature"
        },
        async externalSign(docid,  template){
            var windowReference = window.open('about:blank','_blank');
            var html = "<!DOCTYPE html><html lang=''><head></head><body><div style='display:flex;flex-direction:column;text-align:center;font-size:x-large;'><p>Please wait while we prepare your document...<p></div></body></html>"
            windowReference.document.write(html)
            this.pleaseWaitSign=true
            let req = {
                personName:this.record.name,
                personEmail:this.record.emailAddress,
                documentId:docid,
                externalSignTemplateID:template
            }
            var response = await this.callAPI(req,'startsigndocument')
            //handle response
            if(response.Successful){
                this.pleaseWaitSign=false
                this.listenersConnected=true
                
                this.$nextTick(()=>{
                    windowReference.location=response.url
                })
                
                //windowReference.close()
            }else{
                this.pleaseWaitSign=false
                this.responseMessage = response.Message
                windowReference.close()
            }
        },
        signlinkClass(doc){
            if(doc.requireSignature){return "signlinkrequired"}
            else{return "signlink"}
        },
        doctitleClass(doc){
            if(doc.fileName_Signature==null){
                return "doctitle"
            }else{
                return "doctitlesigned"
            }
        },
        async GetDocuments(){
            if(this.listenersConnected){this.listenersConnected=false}
            this.pleaseWait=true
            console.log('docs')
            var basepath = this.imageBasePath 
            console.log('base ' + basepath)
            let req = {
                personId:this.record.personId,
                locationId:this.record.locationId
            }

            //DECIDE if we are doing docs by loc or not. default is no
            var docbyloc = false;
            if(window.DOCUMENTS_BY_LOCATION!=undefined){
                docbyloc=window.DOCUMENTS_BY_LOCATION;
            }

            var response;
            if(docbyloc){
                response= await this.callAPI(req,'documents_bylocation')
            } else{
                response= await this.callAPI(req,'documents')
            }
            //handle response
            if(response.Successful){
                this.DocumentList=response.DocumentList
                this.$refs.mymodal.openModal()
                this.DocumentList.forEach(myFunction2)
                
                function myFunction2(item, index, arr) {
                    let acct=arr[index]
                    if(acct.fileName!=null){
                        let newpath = basepath + acct.fileName
                        console.log('b ' + acct + ' ' + newpath)
                        acct.fileName = newpath
                    }
                    if(acct.fileName_Signature!=null && acct.fileName_Signature!="externalsignature"){
                        let newpath2 = basepath + acct.fileName_Signature
                        acct.fileName_Signature = newpath2
                    }

                    
                } 
                
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false
        },
        
        saveimg_document(file,url,typeId){
            this.storeDocument("doc", file, file.name,typeId)
        },
        async savesign_document(file,url,docname,docid, enteredName){
            console.log('savesign')
             var pId=this.record.personId
            var postParams = new FormData();

            postParams.append("sign",file,name);
            postParams.append('docname',docname);
            postParams.append('docid',docid);
            postParams.append('enteredname',enteredName);

            var response = await this.callAPI(postParams,'savesignature')
            //handle response
            if(response.Successful){
                this.GetDocuments();  
            }else{
                this.responseMessage = response.Message
            }
        },
        async storeDocument(filename,file, name,typeId){
             var pId=this.record.personId
            var postParams = new FormData();

            postParams.append(filename,file,name);
            
            postParams.append('personId',pId);
            postParams.append('typeId',typeId);

            var response = await this.callAPI(postParams,'savedocument')
            //handle response
            if(response.Successful){
                //if we have a doc to deactivate then ask, else get docs
                if(this.docIdToDeactivate>0){
                    this.askToDeactivate()
                }else{
                    this.GetDocuments();
                }
                  
            }else{
                this.responseMessage = response.Message
            }
             
        },
        async askToDeactivate(){
            this.$refs.confirmDeactivate.Show()
        },
        async deactivateDocument(doc){
            if(doc==0){return}
            let req = {
                documentId:doc
            }

            var response = await this.callAPI(req,'deactivatedocument')
            //handle response
            if(response.Successful){
                this.GetDocuments();  
            }else{
                this.responseMessage = response.Message
            }
             
        },
        openDocImage(doc){
            //if we can replace and we have a file already, then stash the id!
            if(doc.canReplace_Admin && doc.fileDisplayName!=null){
                this.docIdToDeactivate=doc.id
            }else{this.docIdToDeactivate=0}

              console.log(doc.typeId)
            this.$refs.imgdoc.referenceID=doc.typeId
           this.$refs.imgdoc.allowFileSelect=true
            this.$refs.imgdoc.allowCapture=true
            this.$refs.imgdoc.openModal() 
        },
        openSignModal(docname,docid,doctypename){
           /*  this.$refs.signdoc.referenceDoc=docname
            this.$refs.signdoc.documentId=docid */
            this.$refs.signdoc.setValues(docname,docid,doctypename)
            this.$refs.signdoc.openModal()
        },
        async getDocumentImageBasePath(){
            //call api
            var response = await this.callAPI(null,'imagepath')
            //handle response
            if(response.Successful){
                this.imageBasePath = response.StringResponse
            }else{
                this.responseMessage = response.Message
            }
        },


        /* async View(){
             let req = {
                locationId:-1,
                badgeId:this.record.badgeId,
                
            }
            
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'alertlog')
            if(response.Successful){
                this.log=response.AlertLogList
                this.$refs.mymodal.openModal()
  
                
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
            
        }, */
        
        
    },
    mounted(){
      this.getDocumentImageBasePath()
      
    }
}
</script>

<style>
.history{
    margin-top:15px;
    display:flex;
    flex-direction: column;
    align-items: center;
}
form{
    font-size:large;
}
.entryCheckLabel{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
}
.entryCheckLabelbold{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
    font-weight:bold;
}
.rightjustify{
    text-align: right;
    
}
.nomargin{
    margin:0px;
}
.alertlink{
    font-size:large;
}
.logitem{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    font-size:medium;
    border:1px solid darkgray;
    margin:5px;
    padding:2px;
}

.docDiv{
    overflow-x:auto;
    overflow-y:scroll;
    height:370px;
}
.documentitems{
    display:grid;
    grid-template-columns:1fr 3fr 1fr;
    text-align:left;
    font-size:medium;
    margin:10px;
}
.doctitle{
    font-weight:bold;

}
.doctitlesigned{
    font-weight:bold;
    color:green;
}
.docbutton{
    height:20px;
    width:30px;
    border:none;
    background-color:transparent;
}
.docimg{
    width:20px;
    background-color:transparent;
    margin:0px;
}
.docimg2{
    width:20px;
    background-color:transparent;
    
}
.col2{
    grid-column: 2;
}
.col3{
    grid-column:3;
    padding-right:10px;
   
}
.required{
    color:red;
}
.signaturebox{
    height:200px;
    width:500px;
    margin:auto;
    background-color:white;
    border:1px solid black;
}
.signlink{
    margin-top:10px;
}
.signlinkrequired{
    margin-top:10px;
    color:red;
}
.pleasewaitdoc{
    color:orangered;
    font-size:large;
}
</style>





























