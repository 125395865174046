<template>
<div>
    <div class="vertFlexLeft">
        <label class="titlelabel bottom10">{{title}}</label>
    </div>
    <div class="itemsdiv bottom10">
        <div v-for="item in itemList" :key="item.ID" :value="item.ID" >
            <lookupresult :topLeft="item.Name"   @click="selectItem(item)"
                :selected="isSelected(item)" :detail="item.Description">
            </lookupresult>  
        </div>
    </div>
    
    <div>
        <div class=" buttons">
            <div>
                <button class="repbutton buttonhighlight" @click="$emit('add')">Add</button>
            </div>
            <div>
                <button class="repbutton buttonprimary" @click="startedit()" :disabled="selectedItem.id==0">Edit</button>
                <button class="repbutton buttonprimary leftmargin" @click="startremove()" :disabled="selectedItem.id==0">Remove</button>
                <button class="repbutton buttonprimary leftmargin" @click="startcopy()" :disabled="selectedItem.id==0">Copy</button>
            </div>
            
        </div>
    </div>
    
</div>
  
</template>

<script>
import smallbutton from '../controls/button.vue'
import lookupresult from '../controls/lookupresult.vue'
export default {
    name:'manageitems',
    props:{
        itemList:{
            type:Array,
            
        },
        title:{
            type:String,
            default:"Items"
        }
        
    },
    components:{
        smallbutton,
        lookupresult,
    },
    data(){
        return{
            selectedItem:{
                id:0
            }
        }
    },
    methods:{
        selectItem(item){
            console.log('sel ' + item.ID)
            this.selectedItem=item

        },
        //allow select by id so we can trigger it from outside
        selectItemByID(id){
            var itemtoselect=null
            console.log('selbyid ' + id + ' ' + this.itemList.length)
            this.itemList.forEach(myFunction2)
            
            function myFunction2(item, index, arr) {
                let thisitem=arr[index]
                 console.log("checking... " + thisitem.ID)
                if(thisitem.ID==id){
                     console.log("found one " + thisitem.ID)
                    itemtoselect=thisitem
                   
                }
            }
            if(itemtoselect!=null){this.selectItem(itemtoselect)}
        },
        startremove(){
            this.$emit('remove',this.selectedItem.ID)
        },
        startcopy(){
            this.$emit('copy',this.selectedItem.ID,this.selectedItem.Name)
        },
        startedit(){
            this.$emit('edit',this.selectedItem.ID)
        },
        isSelected(item){
            return item.ID==this.selectedItem.ID
        }
    },
    computed:{
        
    }
}
</script>

<style>
.itemsdiv{
    height:300px;
    overflow-x:hidden;
    overflow-y:scroll;
}
.topmargin{
    margin-top:10px;
}
.buttons{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}
.repbutton{
    
   color:white;
   padding:10px;
   border-radius:10px;
   border:none;
   font-size:inherit;
   
   width: 75px;
}
.leftmargin{
    margin-left:5px;
}
</style>