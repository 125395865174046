<template>
  <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <div class="vertFlex">
                <label class="titlelabel">Sign Here</label>
                <br>
                <formentry label="Enter Full Name" isrequired='True' v-model="enteredName" />
                <signaturepad @done="donesign" @cancel="cancel" :enteredName="enteredName" :docName="documentName"/>
                
                <div >

   <!--              
                    <smallbutton toplabel="Save" @click="savepic()"/>
                    <smallbutton toplabel="Cancel" @click="$refs.mymodal.closeModal()"/> -->
                </div>
            </div>
            
        </template>
      </actionmodal>
</template>

<script>
import actionmodal from '../controls/actionmodal.vue'
import smallbutton from './button.vue'
import signaturepad from '../controls/signature.vue'
import formentry from '../controls/entry.vue'
export default {
    name:'signaturemodal',
    components:{
        actionmodal,
        smallbutton,
        signaturepad,
        formentry
    },
    props: {
        
        buttonClass:{
          type:String,
          default:'captureButton'
        },
        buttonLabelClass:{
          type:String,
          default:'buttonlabel'
        },
        pleaseWaitMessage:{
          type:String,
          default:'Please Wait...'
        },
        
        /* referenceDoc:{
            type:String,
            default:''
        },
        documentId:{
          type:String,
        } */

    },
    data() {
		return {

            showSign:false,
            signedImageURL:null,
            signedImage:null,
            enteredName:'',

            referenceDoc:'',
            documentId:'',
            documentName:'',
		};
	},
    computed:{
        
    },
    methods: {
      setValues(refdoc, docid,docname){
          this.referenceDoc=refdoc
          this.documentId=docid
          this.documentName=docname
        },
        sign(){
          this.showSign=true;

        },
        donesign(file,url){
          if(this.enteredName==''){return}
          this.signedImageURL=url;
          this.signedImage=file
            this.savepic()
        },
        openModal(){
           // this.capturedImageURL=require('@/assets/WM_personimage.png')
            this.$refs.mymodal.openModal()
        },
        cancel(){
            this.$refs.mymodal.closeModal()
        },
		
        savepic(){

            if(this.signedImageURL!=null){
              
              this.$emit('savesign',this.signedImage, this.signedImageURL, this.referenceDoc, this.documentId, this.enteredName)
            }
            this.$refs.mymodal.closeModal();
        }
	},
    mounted(){
        
    }
}
</script>

<style>

.pbutton{
    background-color:var(--button-color);
    color:var(--light-text-color);
    border: none;
    padding-bottom:5px;
    min-width:120px;
    height:40px;
    
    font-size: medium;
    margin:10px;

    text-decoration: none;

}
.image{
  margin:10px;
  height:90%;
}
.imagedivdoc{
  border: 2px solid var(--primary-color);
  background-color:white;
  display:flex;
  flex-direction: column;
  align-items: center;
  width:80%;
  height:600px;
  margin:auto;
}
</style>