<template>
<div>
    <div class="btn-cont">
      <div id="google-login-btn" v-google-identity-login-btn="{ clientId, locale:'en' }">
          Continuar con google
      </div>
    </div>
    <label>{{resultmsg}}</label>
</div>
</template>

<script>
import GoogleSignInButton from 'vue-google-identity-login-btn';
export default {
    directives: {
        GoogleSignInButton
    },
  name: 'googleidentity',
  props: {
    msg: String
  },
  data(){
    return{
      resultmsg:'',
      clientId: window.GOOGLE_LOGIN,
      profile:null,
      token:null,

    }
  },
  methods:{
    onGoogleAuthSuccess (jwtCredentials) {
      console.log(jwtCredentials);
      this.profile = JSON.parse( atob(jwtCredentials.split('.')[1]) );
      this.token = jwtCredentials;
      //console.table(this.profile);
      this.$emit('loggedin',this.token)
    },
   
    onSignInError (error) {
      // `error` contains any error occurred.
      console.log('ERROR', error)
    }
  },
  

}
</script>

<style>
.btn-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 500px;
}
.btn-cont > div {
  width: 300px;
  text-align: center;
}
.g-signin-button:hover{
    background-color:var(--highlight-color);
  cursor:pointer;
}
</style>