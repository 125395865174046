<template>
<div>
    <button class="gobutton buttonprimary" @click="start()"  >Scheduler</button>

 

     <!-- edit -->
    <actionmodalwide ref="mymodal" > 
        <template v-slot:body>
             <div v-if="!inEdit">
                <manageitems v-if="!isAdd" ref="schedreplist" title="Scheduled Reports" :itemList="itemList" @add="add()"  @remove="remove" @copy="copy" @edit="edit"/>
                <reportlookup ref="lookup" v-if="isAdd" @saveandschedule="saveandschedule" @cancel="isAdd=false"/>

                <div>
                    <label v-if="pleaseWait" class="pleasewait">Please Wait...</label>
                </div>
                <button class="gobutton buttonprimary" @click="$refs.mymodal.closeModal()" v-if="!isAdd" >Close</button>
            </div>
            <div v-if="inEdit">
                <div class="bordered vertFlexLeft">
                    
                    <label class="titlelabel bottom10">Report {{selectedReport.Name}}</label>
                       
                    <div class="margin10">
                        <label>Name </label>
                        <input class="nameinput" v-model="selectedReport.Name"/>
                    </div>
                </div>
                <br>

                <div class="halves">
                    <!-- schedule -->
                    <div class="vertFlexLeft bordered marginright">
                        <div class="">
                            <label class="titlelabel bottom10">Schedule</label>
                        </div>
                        <div class="margin10">
                            <label>Run at </label>
                            <select name="" id="" class='editCombo' v-model="selectedReport.Schedule.HourOfDay" >
                                <option v-for="time in timeList" :key="time.id" :value="time.id">
                                    {{time.name}}
                                </option>
                            </select>
                        </div>
                        <div class="margin10">
                            <input type="radio" name="timeradio" value="Daily" v-model="timeFrame" @click="selectDaily()"/> 
                            <label>Daily </label>
                            
                        </div>
                        <div class="margin10">
                            <input type="radio" name="timeradio" value="Weekly" v-model="timeFrame" @click="selectWeekly()"/> 
                            <label>Weekly on </label>
                            <div class="horizFlex">
                                <div  class="leftindent vertFlex">
                                    <div>
                                        <input type="checkbox" v-model="selectedReport.Schedule.Monday" @click="selectWeekly()"/>
                                        <label>Monday</label> 
                                    </div>
                                        <div>
                                    <input type="checkbox" v-model="selectedReport.Schedule.Tuesday" @click="selectWeekly()"/>
                                    <label>Tuesday</label> 
                                    </div>
                                        <div>
                                    <input type="checkbox" v-model="selectedReport.Schedule.Wednesday" @click="selectWeekly()"/>
                                    <label>Wednesday</label> 
                                    </div>
                                    <div>
                                        <input type="checkbox" v-model="selectedReport.Schedule.Thursday" @click="selectWeekly()"/>
                                        <label>Thursday</label> 
                                    </div>
                                    
                                        
                                    
                                </div>
                                <div  class="leftindent vertFlex">
                                    <div>
                                        <input type="checkbox" v-model="selectedReport.Schedule.Friday" @click="selectWeekly()"/>
                                        <label>Friday</label> 
                                    </div>
                                    <div>
                                        <input type="checkbox" v-model="selectedReport.Schedule.Saturday" @click="selectWeekly()"/>
                                        <label>Saturday</label> 
                                    </div>
                                    <div>
                                        <input type="checkbox" v-model="selectedReport.Schedule.Sunday" @click="selectWeekly()"/>
                                        <label>Sunday</label> 
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div class="margin10">
                            <input type="radio" name="timeradio" value="Monthly" v-model="timeFrame" @click="selectMonthly()"/> 
                            <label>Monthly on  </label>
                            
                                <select name="" id="" class='editCombo' v-model="selectedReport.Schedule.DayOfMonth1" @change="selectMonthly()">
                                    <option v-for="day in dayList" :key="day.id" :value="day.id" >
                                        {{day.name}}
                                    </option>
                                </select>
                                
                          
                            <div class="leftindent">
                                <label> and </label>
                                <select name="" id="" class='editCombo' v-model="selectedReport.Schedule.DayOfMonth2" @change="selectMonthly()">
                                    <option v-for="day in dayList" :key="day.id" :value="day.id">
                                        {{day.name}}
                                    </option>
                                </select>
                            </div>
                            
                        </div>
                        <div class="margin10">
                        <hr>
                        </div>
                    
                        <div class="margin10">
                                <input type="radio" name="durationradio" value="Always" v-model="duration" @click="setAlways()"/> 
                                <label>Send Always </label>
                        </div>
                        <div class="margin10">
                                <input type="radio" name="durationradio" value="Dates" v-model="duration" @click="setDates()"/> 
                                <label>from </label>
                                <input type="date" class="dateinput" v-model="selectedReport.EffectiveDate"/>
                            <div class="leftindent">
                                
                                <label> to </label>
                                <input type="date" class="dateinput"  v-model="selectedReport.ExpirationDate"/>
                            </div>
                                
                        </div>
                    </div>

                    <!-- email -->
                    <div class="vertFlexLeft bordered">
                        <div class="">
                            <label class="titlelabel bottom10">Email To</label>
                        </div>
                        <div class="margin10">
                            <label>Format </label>
                            <input type="radio" name="reporttyperadio" value="1" v-model="selectedReport.Schedule.ReportOutputFormatID"/> 
                            <label>Acrobat </label>
                            <input type="radio" name="reporttyperadio" value="2" v-model="selectedReport.Schedule.ReportOutputFormatID"/> 
                            <label>Excel </label>
                        </div>
                    
                        <div class="margin10">
                            <div class="horizFlex">
                                <label>Recipients </label>
                                
                            </div>
                            
                            <div class="vertFlex leftindent">
                                <div v-for="recipient in recipientList" :key="recipient.ID" :value="recipient.ID">
                                    <input type="checkbox" v-model="recipient.selected" @change="selectRecip(recipient)"/>
                                    <label >
                                        {{recipient.Name}} ({{recipient.EmailAddress}})
                                    </label>
                                </div>
                                <addrecipient @refresh="getEmailRecipientList"/>
                            </div>
                        
                        </div>
                    </div>
                </div>
                

                <div class="bordered" v-if="false">
                    <div class="margin10">
                        history
                    </div>
                    
                </div>
                <br>
                <div class="">
                    <button class="innerbutton buttonhighlight" @click="save">Save</button>
                    <button class="innerbutton buttonprimary" @click="cancel">Cancel</button>
                </div> 
                    
                
            </div>
           
        </template>
      </actionmodalwide>
      <confirm ref="confirmremove" title="Remove Scheduled Report" body="Do you want to remove the selected scheduled report?" @yes="yesremove"/>
</div>
  
</template>

<script>
import {ReportHTTP} from '../../repository'
import format from '../../format.js'
import lookupviewlayout from '../layouts/lookupviewlayout.vue'
import actionmodalwide from '../controls/actionmodalwide.vue'
import actionmodal from '../controls/actionmodal.vue'
import reportlookup from './ReportLookup.vue'
import lookupresult from '../controls/lookupresult.vue'
import manageitems from './manageitems.vue'
import confirm from '../actions/confirm.vue'
import addrecipient from './addRecipient.vue'
export default {
    name:'reportscheduler',
    components:{
        actionmodalwide,
        lookupviewlayout,
        reportlookup,
        actionmodal,
        lookupresult,
        manageitems,
        confirm,
        addrecipient
    },
    mixins:[format],
    data(){
        return{
            pleaseWait:false,
            inEdit:false,
            isAdd:false,
            selectedReport:null,
            recipientList:[],
            selectedId:0,
            timeFrame:'Daily',
            duration:'Always',

            timeList:[
                {
                    id:-1,
                    name:""
                },{
                    id:0,
                    name:"12 AM"
                },
                {
                    id:1,
                    name:"1 AM"
                },
                {
                    id:2,
                    name:"2 AM"
                },
                {
                    id:3,
                    name:"3 AM"
                },
                {
                    id:4,
                    name:"4 AM"
                },
                {
                    id:5,
                    name:"5 AM"
                },
                {
                    id:6,
                    name:"6 AM"
                },
                {
                    id:7,
                    name:"7 AM"
                },
                {
                    id:8,
                    name:"8 AM"
                },
                {
                    id:9,
                    name:"9 AM"
                },
                {
                    id:10,
                    name:"10 AM"
                },
                {
                    id:11,
                    name:"11 AM"
                },   
                {
                    id:12,
                    name:"12 PM"
                },
                {
                    id:13,
                    name:"1 PM"
                },
                {
                    id:14,
                    name:"2 PM"
                },
                {
                    id:15,
                    name:"3 PM"
                },
                {
                    id:16,
                    name:"4 PM"
                },
                {
                    id:17,
                    name:"5 PM"
                },
                {
                    id:18,
                    name:"6 PM"
                },
                {
                    id:19,
                    name:"7 PM"
                },
                {
                    id:20,
                    name:"8 PM"
                },
                {
                    id:21,
                    name:"9 PM"
                },
                {
                    id:22,
                    name:"10 PM"
                },
                {
                    id:23,
                    name:"11 PM"
                },
            ],
            dayList:[
                {
                    id:0,
                    name:"None"
                },

            ],
            weekdayList:[
                {
                    id:0,
                    name:"Monday"
                },
                {
                    id:1,
                    name:"Tuesday"
                },
                {
                    id:2,
                    name:"Wednesday"
                },
                {
                    id:3,
                    name:"Thursday"
                },
                {
                    id:4,
                    name:"Friday"
                },
                {
                    id:5,
                    name:"Saturday"
                },
                {
                    id:6,
                    name:"Sunday"
                }
            ],
            itemList:[
             
            ]
        }
    },
    methods:{
        markRecipients(){
           var recipientList = this.recipientList
            //first clear recip
            recipientList.forEach(myFunction4)
            function myFunction4(item, index2, arr2) {
                let recip2=arr2[index2]
                recip2.selected = false
            }
            this.selectedReport.EmailRecipientList.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let recip=arr[index]

                recipientList.forEach(myFunction3)
                function myFunction3(item, index2, arr2) {
                    let recip2=arr2[index2]
                    if(recip.EmailAddress==recip2.EmailAddress){
                        console.log('match')
                        recip2.selected = true
                    }
                }
            }
        },
        selectRecip(selrecip){
            var found=false
            this.selectedReport.EmailRecipientList.forEach(myFunction2)
            
            function myFunction2(item, index, arr) {
                let recip=arr[index]
                if(recip.EmailAddress==selrecip.EmailAddress){
                    recip.selected=!recip.selected
 
                    found=true
                }
            }
            //if we didn't find it, then add it
            if(!found){
          
                this.selectedReport.EmailRecipientList.splice(0,0,selrecip)
            }
        },
        add(){
            console.log('add')
            this.isAdd=true
        },
        remove(selectedId){
            this.selectedId=selectedId
            this.$refs.confirmremove.Show()
            console.log('remove ' + selectedId)
        },
        async yesremove(){
            
            
            let req = {
                scheduledReport:{'ID':this.selectedId}
            }
            this.pleaseWait=true;
            ReportHTTP.post('removescheduledreport',req)
            .then(response=>{
                this.getScheduledReportList()
                this.pleaseWait=false;
                console.log(response)
            })
            .catch(e=>{
                this.pleaseWait=false;
                if(e.message=='Network Error'){
                    this.errorMessage = "Report Error: Cannot Reach API"
                }
                this.errorMessage = "Report Error: " + e.message;

                console.log(e)
                return e
            });
        },
        copy(selectedId,name){
            this.selectedId=selectedId
            console.log('copy ' + selectedId)
            let req = {
                scheduledReport:{
                    ID:selectedId,
                    Name:name
                }
            }
            this.pleaseWait=true;
            ReportHTTP.post('copyscheduledreport',req)
            .then(response=>{
                this.selectedId=response.data.ResponseInteger
                this.getScheduledReportList()
               // this.inEdit=true
            this.getScheduledReport(this.selectedId)
                this.pleaseWait=false;
                console.log(response)
            })
            .catch(e=>{
                this.pleaseWait=false;
                if(e.message=='Network Error'){
                    this.errorMessage = "Report Error: Cannot Reach API"
                }
                this.errorMessage = "Report Error: " + e.message;

                console.log(e)
                return e
            });
        },
        edit(selectedId){
            this.selectedId=selectedId
            console.log('edit ' + selectedId)
           // this.inEdit=true
            this.getScheduledReport(selectedId)
        },
        save(){
            

            let req = {
                scheduledReport:this.selectedReport
            }

            this.pleaseWait=true;
            ReportHTTP.post('savescheduledreport',req)
            .then(response=>{
                this.selectedId=response.data.ScheduledReport.ID
                this.getScheduledReportList()
                console.log(response.data)
                this.pleaseWait=false;
                this.inEdit=false
            })
            .catch(e=>{
                this.pleaseWait=false;
                if(e.message=='Network Error'){
                    this.errorMessage = "Report Error: Cannot Reach API"
                }
                this.errorMessage = "Report Error: " + e.message;

                console.log(e)
                return e
            });
            
        },
        cancel(){
            this.inEdit=false
        },
        selectItem(){

        },
        start(){
            this.setupDays()
            this.getEmailRecipientList()
            this.getScheduledReportList()
            this.$refs.mymodal.openModal()
        },
        setupDays(){
            for(let i = 1; i<32; i++){
                this.dayList.push({id:i,name:i.toString()})
            }
        },
        saveandschedule(newReportId){
            console.log("saved and ready to schedule " + newReportId)
            this.isAdd=false
            this.getScheduledReport(newReportId)
            //this.inEdit=true


           


        },
        async getScheduledReportList(){
            //keep track of the one we had selected
       
             let req = {
                SalonID:this.locationID,
            }
            this.pleaseWait=true;
            ReportHTTP.post('scheduledreportlist',req)
            .then(response=>{
                this.itemList = response.data.ScheduledReportItemList

                //select the one we had if we can
                console.log("about to select " + this.selectedId)
                this.$nextTick(()=>{
                    this.$refs.schedreplist.selectItemByID(this.selectedId)
                })
                
                this.pleaseWait=false;
                console.log(response)
            })
            .catch(e=>{
                this.pleaseWait=false;
                if(e.message=='Network Error'){
                    this.errorMessage = "Report Error: Cannot Reach API"
                }
                this.errorMessage = "Report Error: " + e.message;

                console.log(e)
                return e
            });
            
        },
        async getEmailRecipientList(){
             let req = {
                SalonID:this.locationID,
            }
            this.pleaseWait=true;
            ReportHTTP.post('emailrecipientlist',req)
            .then(response=>{
                this.recipientList = response.data.EmailRecipientList
                
                this.pleaseWait=false;
                console.log(response)
            })
            .catch(e=>{
                this.pleaseWait=false;
                if(e.message=='Network Error'){
                    this.errorMessage = "Report Error: Cannot Reach API"
                }
                this.errorMessage = "Report Error: " + e.message;

                console.log(e)
                return e
            });
            
        },
        async getScheduledReport(selectedId){
            let req = {
                scheduledReport:{'ID':selectedId}
            }
            this.pleaseWait=true;
            ReportHTTP.post('scheduledreport',req)
            .then(response=>{
                this.selectedReport = response.data.ScheduledReport
                this.selectedReport.EffectiveDate=this.$options.filters.formatDateForInput(this.selectedReport.EffectiveDate)
                this.selectedReport.ExpirationDate=this.$options.filters.formatDateForInput(this.selectedReport.ExpirationDate)
                if(this.selectedReport.ExpirationDate!=null){ this.duration="Dates"}
                if(this.selectedReport.Schedule.Daily){
                    this.selectDaily()
                }else if(this.selectedReport.Schedule.DayOfMonth1!=0){
                    this.selectMonthly()
                }else{
                    this.selectWeekly()
                }
                this.markRecipients()
                this.pleaseWait=false;
                this.inEdit=true
                console.log(response)
            })
            .catch(e=>{
                this.pleaseWait=false;
                if(e.message=='Network Error'){
                    this.errorMessage = "Report Error: Cannot Reach API"
                }
                this.errorMessage = "Report Error: " + e.message;

                console.log(e)
                return e
            });
            
        },
        selectDaily(){
            //clear weekly and monthly
            this.clearWeekly();
            this.clearMonthly();
            this.selectedReport.Schedule.Daily=true
            this.timeFrame="Daily"
        },
        selectWeekly(){
            this.clearMonthly();
            this.selectedReport.Schedule.Daily=false
            this.timeFrame="Weekly"
        },
        selectMonthly(){
            this.clearWeekly();
            this.selectedReport.Schedule.Daily=false
            this.timeFrame="Monthly"
        },
        clearWeekly(){
            this.selectedReport.Schedule.Monday=false
            this.selectedReport.Schedule.Tuesday=false
            this.selectedReport.Schedule.Wednesday=false
            this.selectedReport.Schedule.Thursday=false
            this.selectedReport.Schedule.Friday=false
            this.selectedReport.Schedule.Saturday=false
            this.selectedReport.Schedule.Sunday=false
        },
        clearMonthly(){
            this.selectedReport.Schedule.DayOfMonth1=0
            this.selectedReport.Schedule.DayOfMonth2=0
        },
        setAlways(){
            this.duration="Always"
            this.selectedReport.ExpirationDate='1-01-01';
        },
        setDates(){
            this.duration="Dates"
        }

    },
    computed:{
       
        isAlways(){
            if(this.selectedReport.Schedule.ExpirationDate=='1-01-01'){
                return true
            }else{return false}
        },
        

    },
    mounted(){
        
    }
}
</script>

<style>
:root{

    --text-color:rgb(58, 54, 54)!important;
    --light-text-color:white!important;
}
.bordered{
    background-color: white;
    border-radius: 5px;
    padding:10px;
}
.marginright{
    margin-right:20px;
}
.gobutton{
    
   color:white;
   padding:10px;
   border-radius:10px;
   border:none;
   font-size:inherit;
   margin:10px 0px 0px 10px;
   min-width:130px;
    height:50px;
}
.buttonprimary{
    background-color:var(--button-color);
}
.buttonhighlight{
    background-color:var(--highlight-color);
}
.innerbutton{
    
   color:white;
   padding:10px;
   border-radius:10px;
   border:none;
   font-size:inherit;
   margin-bottom:20px;
   margin-left:5px;
   width: 75px;
}

.recipbutton{
   background-color:rgb(0,158,192);
   color:white;
   padding:5px;
   border-radius:10px;
   border:none;
   font-size:inherit;
   margin-bottom:10px;
   margin-left:5px;
   width: 70px; 
}
.margin10{
    margin:5px;
}
.vertFlex{
    display:flex;
    flex-direction: column;

}
.leftindent{
    margin-left:20px;
}
.editCombo{
    width:100px;
}
.halves{
    display:grid;
    grid-template-columns: 1fr 1fr;
}
.dateinput{
    width:120px;
    font-style:inherit;
    font-size:inherit;
    margin:5px;
}
.horizFlexStretch{
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
  
}
.nameinput{
    width:400px;
}
input{
    font-style:inherit;
}
.pleasewait{
    color:orangered;
    font-size:x-large;
}
</style>