<template>
<div class="maindiv">
    <smallbutton  @click="start()" toplabel="Welcome Letter" />
    <!-- MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <label class="titlelabel">Send Welcome Letter</label>
            <formentry  isrequired="True" label="Email"  v-model="emailaddress"  />
            <div>
                <smallbutton  @click="closeModal" toplabel="Send"/>
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Cancel"/>
            </div>
            <div>
              <label class="responsemessage">{{responseMessage}}</label>
            </div>
        </template>
      </actionmodal>
    </div>
</template>

<script>
import datamethods from '../../datamethods.js'
import smallbutton from '../controls/button.vue'
import actionmodal from '../controls/actionmodal.vue'
import linkbutton from '../controls/linkbutton.vue'
import formentry from '../controls/entry.vue'

export default {
    name:'sendemail',
    components:{
        smallbutton,
        actionmodal,
        linkbutton,
        formentry
    },
    props:{
     
        
    },
    mixins:[datamethods],
    data(){
        return{
            emailaddress:'',
            responseMessage:''
        }
    },
    methods:{
        start(){

            this.emailaddress=''
            this.$refs.mymodal.openModal()
        },
        async closeModal(){
            this.$refs.mymodal.closeModal()
        }
    }
}
</script>

<style>
.maindiv{
    font-size:medium;
}
</style>