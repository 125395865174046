<template>

    <form >
        <label class="titlelabel">{{title}}</label>
        <div>
            <label for="" class="pleasewait" v-if="pleaseWait">Please Wait...</label>
        </div>
        <div  class="msgframe">
            <div class="col">
                
                <label >Please select which message to send</label>
                <div>
                    <select name="" id="" class='filtertemplate' v-model="currentItem.badgeAlertTemplateId" >
                        <option v-for="template in textTemplateList" :key="template.id" :value="template.id" >
                            {{template.name}}
                        </option>
                    </select>
                    <div>
                        <textarea lines="3" v-model="msgText" v-if="currentItem.badgeAlertTemplateId==-2" class="msg"></textarea>
                    </div>
                    <smsexample :message1="text1"/>
                </div>
                
                <div class="entry">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Applies to Location</label>
                    </div>
                    <select name="" id="" class='entrySelect' v-model="currentItem.locationId">
                        <option v-for="location in locations" :key="location.id" :value="location.id" >
                            {{location.name}}
                        </option>
                    </select>
                </div>
                <!-- <div class="entry">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Applies to Department</label>
                    </div>
                    <select name="" id="" class='entrySelect' v-model="currentItem.departmentId" >
                        <option v-for="department in departments" :key="department.id" :value="department.id" >
                            {{department.name}}
                        </option>
                    </select>
                </div> -->
                <!-- <div class="entry">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Applies to Role</label>
                    </div>
                    <select name="" id="" class='entrySelect' v-model="currentItem.personRoleId" >
                        <option v-for="role in personRoleList" :key="role.id" :value="role.id" >
                            {{role.name}}
                        </option>
                    </select>
                </div> -->
                <div class="entry">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">All Employees</label>
                    </div>
                    <div class="entrycheck">
                    <input type="checkbox"  v-model="currentItem.sendToAllEmployees" />
                    </div>
                </div>
                <div class="entry">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">All Students</label>
                    </div>
                    <div class="entrycheck">
                    <input type="checkbox"  v-model="currentItem.sendToAllStudents" />
                    </div>
                </div>
                <!-- <div class="entry">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">All Visitors</label>
                    </div>
                    <div class="entrycheck">
                    <input type="checkbox"  v-model="currentItem.sendToAllVisitors" />
                    </div>
                </div> -->
                <div class="entry">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Include Inactive</label>
                    </div>
                    <div class="entrycheck">
                    <input type="checkbox"  v-model="currentItem.includeInactive" />
                    </div>
                </div>
                <div class="entry">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Amount Due</label>
                    </div>
                    <div class="entrycheck">
                    <input type="checkbox"  v-model="currentItem.onlyAmountDue" />
                    </div>
                </div>
                <formentry :label="startDate1Text"  v-model="currentItem.startDate1"  inputtype="date"/>
                <formentry :label="startDate2Text"  v-model="currentItem.startDate2"  inputtype="date"/>
                <formentry label="Graduation Date"  v-model="currentItem.endDate"  inputtype="date"/>
            
            </div>

        </div>
        <div >
            <smallbutton @click="getgroup" toplabel="Get Text List" buttonclass="buttonsmaller buttonprimary"/>
            <textlist ref="textlist" title="Text List" :textlist="badgeList"/>
            <confirm ref="confirm" :showButton="falseParam" :showImageButton="falseParam" :body='smsconfirmtext' title="Send" buttonclass="buttonsmaller buttonprimary" @yes="save()"/> 
            <smallbutton  @click="checksend()" toplabel="Send" buttonclass="buttonsmaller buttonprimary"/>
            <smallbutton  @click="cancel()" toplabel="Clear" buttonclass="buttonsmaller buttonprimary"/>
        </div>
        
        <div class="msgscroll">
            <label class="responsemessage wrap">{{message}}</label>
        </div>
    </form>
</template>

<script>
import datamethods from '../../datamethods.js'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import formentry from '../controls/entry.vue'
import {mapFields} from 'vuex-map-fields'
import smsexample from '../actions/smsexample.vue'
import textlist from '../actions/textlist.vue'
import confirm from '../actions/confirm.vue'
export default {
    name:'sendsmsframe',
    components:{
        smallbutton,
        formentry,
        smsexample,
        textlist,
        confirm

    },
    props:{

        
    },
    mixins:[datamethods,format],
    data(){
        return{
            title:'Send Text',

            currentItem:{
                badgeAlertTemplateId:-1,
                locationId:-1,
                departmentId:-1,
                personRoleId:-1,
                badgeId:null,
                sendToAllEmployees:false,
                sendToAllStudents:false,
                sendToAllVisitors:false,
                overrideEffectiveDateTime:null,
                overrideExpirationDateTime:null,
                overrideCanBeDismissed:false,
                includeInactive:false,
                onlyAmountDue:false,
                startDate1:null,
                startDate2:null,
                endDate:null
            },
            message:'',
            itemList:[],


            pleaseWait:false,
            multirows:2,
            badgeList:[],
            badgeListCount:0,
            textTemplateList:[],
            msgText:null,
            falseParam:false
        }
    },
    computed:{
      ...mapFields([
            'locations',
            'personRoleList',
            'departments',
            'smsTemplateList'

        ]),
        smsconfirmtext(){
            return "Do you want to send this text to " + this.badgeListCount + " people?"
        },
        startDate1Text(){
            
            if(window.START_DATE1_TEXT==undefined){
                return 'Start Date'
            }else{return window.START_DATE1_TEXT}
        },
        startDate2Text(){
            
            if(window.START_DATE2_TEXT==undefined){
                return 'Start Date 2'
            }else{return window.START_DATE2_TEXT}
        },
        text1(){
            var txt
            var id=this.currentItem.badgeAlertTemplateId
            this.textTemplateList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let a=arr[index]
                    if(a.id==id){
                        txt=a.sms
                    }
                } 
            return txt
        },
        allowCustomSMS(){
                if(window.ALLOW_CUSTOM_SMS==undefined){return false}
                else{return window.ALLOW_CUSTOM_SMS}
            },
    },
    methods:{
        checksend(){
            this.getgroup(false)
        },
        async getTemplates(){
              
              var response = await this.callAPI(null,'templates_sms')
              if(response.Successful){
                  console.log(response)
                  
                  this.textTemplateList=response.MessageTemplateList
                  
                  if(this.allowCustomSMS){
                        var custom = {
                            id:-2,
                            name:'Custom Message'
                        }
                        this.textTemplateList.unshift(custom)
                        this.currentItem.badgeAlertTemplateId=-1
                    }

                  //this.$refs.mymodal.openModal()
              }else{
                  //this.message = response.Message
              }
  
          },


      
        async Start(){
            
            this.pleaseWait=true
  

        },
        cancel(){
            this.currentItem={
                badgeAlertTemplateId:-1,
                locationId:-1,
                departmentId:-1,
                personRoleId:-1,
                badgeId:null,
                sendToAllEmployees:false,
                sendToAllStudents:false,
                sendToAllVisitors:false,
                overrideEffectiveDateTime:null,
                overrideExpirationDateTime:null,
                overrideCanBeDismissed:false,
                includeInactive:false,
                onlyAmountDue:false,
                startDate1:null,
                startDate2:null,
                endDate:null
            }
            this.message=null
            this.msgText=null
        },
        async save(){
            if(this.currentItem.badgeAlertTemplateId==-1){
                this.message="Please select a text template"
                return
            }
            let req = this.currentItem
            req.msgText=this.msgText

            this.message=""
            this.pleaseWait=true;
            var response = await this.callAPI(req,'sendtexttogroup')
            if(response.Successful){
                
                this.message = response.StringResponse
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;

        },
    
        
        async getgroup(showlist=true){
            
            let req = this.currentItem
            this.message=""
            this.pleaseWait=true;
            var response = await this.callAPI(req,'badgegroup_sms')
            if(response.Successful){
                
                this.badgeList=response.BadgeList
                this.badgeListCount = this.badgeList.length
                if(showlist){
                    this.$refs.textlist.Show()
                }else{
                    this.$refs.confirm.Show()
                }
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;

        },
       
        
    },
    mounted(){
        this.getTemplates();
    }

}
</script>

<style>
.mainform{
    
}
.general{
    font-size:medium;
}
.entryCheckLabel{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
}
.entryCheckLabelbold{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
    font-weight:bold;
}
.imgbutton{
    background-color:var(--button-color);
    color:var(--light-text-color);
    border: none;
    border-radius:10px;
    min-width:130px;
    height:50px;
    padding:0px;
    font-size: medium;
    margin:10px;
  text-decoration: none;
  position:relative;
}
.imgbutton:hover{
    background-color:var(--highlight-color);
  cursor:pointer;
}
.icondiv{
    background-color:blue;
    background:transparent;
}
.icon{
    background:transparent;
    max-width: 250px;;
}
.col{
    padding:5px;
}
.entrycheck{
    text-align: left;
    width:100%;
    margin-top:7px;
    margin-bottom:7px;
}
.entrymulti{
    visibility: visible;
    font-size:inherit;
    margin:0px 5px 0px 5px;
    
    text-align:left;
    resize:none;
    min-height:50px;
    width:100%;
}
.msgframe{
    padding:10px;
}
.wrap{
    white-space: pre-wrap;
    
}
.msgscroll{
    max-width:560px;
    overflow-x:auto;
    overflow-y:scroll;
    max-height:100px;
}
.msg{
        width:295px;
        visibility: visible;
        height:50px;
        margin-top:5px;
    }
</style>





























