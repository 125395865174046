<template>
<div>
    <linkbutton toplabel="View Alert Log" @click="View()" class="alertlink"/>
    <!-- ACCT NOTE MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form >
                <div class="vertFlex">
                    <label class="titlelabel">Alert Log</label>
                
                    <div v-for="item in log" :key="item.id" :value="item.id" class="logitem">
                        <label>{{item.readDateTime | formatDate}}</label>
                        <label>{{item.displayName}}</label>
                        <label>{{item.alertText}}</label>
                        <label>{{item.alertText2}}</label>
                        
                    </div>

                    
                </div>
                <div>
                    <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                </div>
               
          </form>
        </template>
      </actionmodal>
    </div>
</template>

<script>
import datamethods from '../../datamethods.js'
import smallbutton from '../controls/button.vue'
import actionmodal from '../controls/actionmodal.vue'
import linkbutton from '../controls/linkbutton.vue'
import format from '../../format.js'
export default {
    name:'alertlog',
    components:{
        smallbutton,
        actionmodal,
        linkbutton,
        
    },
    props:{
        record:{
            type:Object,
            
        },
        
    },
    mixins:[datamethods, format],
    data(){
        return{
            log:[]
        }
    },
    methods:{
        async View(){
             let req = {
                locationId:-1,
                badgeId:this.record.badgeId,
                
            }
            
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'alertlog')
            if(response.Successful){
                this.log=response.AlertLogList
                this.$refs.mymodal.openModal()
  
                
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
            
        },
        
        
    },
    mounted(){
      
    }
}
</script>

<style>
form{
    font-size:large;
}
.entryCheckLabel{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
}
.entryCheckLabelbold{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
    font-weight:bold;
}
.rightjustify{
    text-align: right;
    
}
.nomargin{
    margin:0px;
}
.alertlink{
    font-size:large;
}
.logitem{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    font-size:medium;
    border:1px solid darkgray;
    margin:5px;
    padding:2px;
}
</style>





























