/* 
PARENT will need an image control, a colorpicker control, and ref to the entrycolor, and these methods

<img id="myimg" :src="getIconFile()" class="icon" crossorigin="anonymous"/>
<entrycolor label="Title Text Color" v-model="design.titleTextColor" @selectcolor="selectcolor_title()"/>
<colorpicker ref="cpicker_title"  @selectcolor="gotcolor_title"/>

selectcolor_title(){
    var img = document.getElementById('myimg')
    this.$refs.cpicker_title.getcolors(img)
    this.$refs.cpicker_title.openModal()
},
gotcolor_title(color){
    this.design.titleTextColor=color
}, 
*/

<template>
    <div class="entry">    
        <div class="entryTextDiv">
            <label class="entryLabel" for="">{{labelText}}</label>
            <label class="required">{{requiredText}}</label>
        </div>
        <input :ref="inputRef" class="entryText capital" :type="inputtype" name=""  :placeholder="placeholder" :step="inputstep"
            :minlength="minlength" :maxlength="maxlength" :value="value" @blur="$emit('blur')" @input="$emit('input',$event.target.value)" 
            :required="isRequired" :max="max" :disabled="disabled" >
        <div class="colorchip" :style="getchipstyle()"></div>    
        <linkbutton toplabel="Select" @click="selectcolor()" class="selectbutton"/>
    </div>
</template>

<script>
import linkbutton from './linkbutton.vue'
export default {
    components:{
        linkbutton
    },
    name:'entrycolor',
    props:{
        label:{
            type:String,
            required:true,
        },
        isrequired:{
            type:String,
            default:'False',
        },
        value:String,
        inputtype:{
            type:String,
            default:'text',
        },
        minlength:{
            type:String,
            default:"0",
        },
        maxlength:{
            type:String,
            default:"25",
        },
        placeholder:{
            type:String,
            default:'',
        },
        max:{
            type:String,
            default:''
        },
        inputRef:{
            type:String,
            default:'myInput'
        },
        inputstep:{
            type:String,
            default:'any'
        },
        disabled:{
            type:Boolean,
            default:false
        }
    },
    data() {
        return {
            labelText:this.label,
            isRequired:this.isrequired=='True' || this.isrequired=='true'
        };
    },
    methods:{
       
        getchipstyle(){
    
            var s = 'background-color: ' + this.value
            return s
        },
        selectcolor(){
            this.$emit('selectcolor')
        }
    },
    computed:{
        requiredText(){
            if(this.isRequired){return "*"}
            else{return ""}
        },
      
        title(){
            if(this.inputtype=='tel'){
               return "10 digit phone number"
            }
        }

    }
}
</script>

<style>
@media screen and (min-width: 812px) {
    .entry{
        flex-direction: row;
    }
}
/* phone */
@media screen and (max-width: 812px) {
   
    .entry{
        flex-direction: column;
    }
}
input:invalid {
  border-color: red;
}
select:invalid {
  border-color: red;
}
.entry{
    width: 100%;
    display:flex;

    justify-content: space-between;
}
.entryLabel{
    margin:5px;
    font-size:medium;
    text-align: left;   
}
.entryCheckLabel{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
}
.entryCheckLabelbold{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
    font-weight:bold;
}
.entryCheckbox{
    margin-left:5px;
}
.entryText{
    margin:5px;
    width:95%;
    font-size:inherit;
    font:inherit;
}
.entrySelect{
    margin:5px;
    width:98%;
    font-size:inherit;
}
.entryTextDiv{
    display:flex;
    flex-direction: row;
    min-width: 250px;
}
.required{
    color:var(--required-color);
    font-size:medium;
    grid-area:req;
    margin:5px;
    text-align: right;
}
.colorchip{
    width:65px;
    height:23px;
    margin-top:5px;

}
.selectbutton{
    margin-top:5px;
}
</style>