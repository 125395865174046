<template>
    <div class="lookupviewvar">
        <div class="lookupvar">
            <slot name="lookup"/>
        </div>
        <div class="viewvar">
            <slot name="viewdetail"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "lookupviewlayout",
}
</script>

<style>
.lookupviewvar{
    margin:0px;
    display:grid;
    grid-template-columns:1fr 3fr;
    grid-template-rows:auto;
    text-align: left;
    
}
.viewvar{
    margin:0px;
    padding:10px;
}
.lookupvar{
    padding:10px;
}

</style>