<template>
  <actionmodalwide ref="mymodal"> 
        <template v-slot:body>
            <div class="vertFlex">
                <div class="imagedivdoc2" >
                    <img alt="selfie image"  :src="capturedImageURL" class="imagedoc" v-if="capturedImageURL!=null"/>
                    <div class="editdiv">
                        <smallbutton toplabel="Edit" buttonclass="editbutton" @click="$refs.imgedit.openModal()" v-if="havePic"/>
                    </div>
                </div>

                <div >
                    <imagecapture ref="imgcapture" :fileAccept="fileAccept" buttonClass="pbutton" :allowFileSelect="allowFileSelect" :allowFileSelectIfNoCapture='allowFileSelectIfNoCapture' @gotimage="gotImage"  :showImage='showImage'  />
                </div>

                <signaturepad @done="donesign" v-if="showSign"/>
                <div>
                  <img alt="sign image"  :src="signedImageURL" class="image" v-if="signedImageURL!=null"/>
                </div>
                <div >

                    <smallbutton toplabel="Sign" @click="sign()" v-if="allowSign"/>
                    <smallbutton toplabel="Save" @click="savepic()"/>
                    <smallbutton toplabel="Cancel" @click="$refs.mymodal.closeModal()"/>
                </div>
            </div>
            <imageeditor ref="imgedit" :img="capturedImageURL" @save="saveedits"/>
            
           
        </template>
      </actionmodalwide>
</template>

<script>
import actionmodalwide from '../controls/actionmodalwide.vue'
import smallbutton from '../controls/button.vue'
import imagecapture from '../controls/ImageCapture.vue'
import imageeditor from '../controls/ImageEditor.vue'
import signaturepad from '../controls/signature.vue'
export default {
    name:'imagealldoc',
    components:{
        actionmodalwide,
        smallbutton,
        imagecapture,
        imageeditor,
        signaturepad
    },
    props: {
        allowCrop:{
            type:Boolean,
            default:true
        },
        allowRotate:{
            type:Boolean,
            default:true
        },
        allowFileSelect:{
          type:Boolean,
          default:false
        },
        allowFileSelectIfNoCapture:{
          type:Boolean,
          default:false
        },
        allowCapture:{
            type:Boolean,
            default:true
        },
        useRearCamera:{
            type:Boolean,
            default:false
        },
        selectedImageClass:{
          type:String,
          default:'selectedImage'
        },
        buttonClass:{
          type:String,
          default:'captureButton'
        },
        buttonLabelClass:{
          type:String,
          default:'buttonlabel'
        },
        pleaseWaitMessage:{
          type:String,
          default:'Please Wait...'
        },
        fileSelectText:{
          type:String,
          default:'Upload File'
        },
        captureText:{
          type:String,
          default:'Take Picture'
        },
        webCamCaptureText:{
          type:String,
          default:'Capture Photo'
        },
        showDeviceInfo:{
          type:Boolean,
          default:false
        },
        showImage:{
          type:Boolean,
          default:true
        },
        fileAccept:{
          type:String,
          default:'*'
        },
        resizeImages:{
          type:Boolean,
          default:true
        },
        referenceID:{
            type:Number,
            default:-1
        },
        allowSign:{
          type:Boolean,
          default:false
        }
    },
    data() {
		return {

            capturedImage:null,
            capturedImageURL:null,
            havePic:false,
            showSign:false,
            signedImageURL:null,
            signedImage:null
		};
	},
    computed:{
        
    },
    methods: {
        sign(){
          this.showSign=true;

        },
        donesign(file,url){
          this.signedImageURL=url;
          this.signedImage=file
          this.showSign=false
        },
        openModal(){
          this.capturedImage=null
          this.capturedImageURL=null
          this.havePic=false
           // this.capturedImageURL=require('@/assets/WM_personimage.png')
            this.$refs.mymodal.openModal()
        },
        gotImage(file,url){
            this.capturedImage=file
            this.capturedImageURL=url
            this.havePic=true
        },
		saveedits(file, url){
            this.capturedImage=file
            this.capturedImageURL=url
        },
        savepic(){
            this.$emit('saveimg',this.capturedImage, this.capturedImageURL, this.referenceID)
            console.log('signme' + this.signedImageURL)
            if(this.signedImageURL!=null){
              
              this.$emit('savesign',this.signedImage, this.signedImageURL, this.referenceID)
            }
            this.$refs.mymodal.closeModal();
        }
	},
    mounted(){
        
    }
}
</script>

<style>

.pbutton{
    background-color:var(--button-color);
    color:var(--light-text-color);
    border: none;
    padding-top:5px;
    min-width:120px;
    height:25px;
    
    font-size: medium;
    margin:10px;

    text-decoration: none;

}
.imagedoc{
  margin:10px;
  height:90%;
}
.imagedivdoc2{
  border: 2px solid var(--button-color);
  background-color:white;
  display:flex;
  flex-direction: column;
  align-items: center;
  height:400px;
  width:90%;
  margin:auto;
}
</style>