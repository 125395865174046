<template>
    <div>
        <!-- <smallbutton :toplabel="title" @click="Show()" :disabled="disabled" :class="buttonclass"/>
  -->
        <!-- MODAL -->
          <actionmodal ref="mymodal"> 
            <template v-slot:body>
                <label class="titlelabel">{{title}} ({{ count }})</label>
                <div class="leftmargin">
                    <div class="vertFlexLeft">
                        <label v-for="b in textlist" :key="b.id" :value="b.id" >
                        {{b.displayName}} ({{ b.phoneNumber }} {{ b.phoneNumber2 }})
                        </label>
                        
                    </div>
                </div>
                

                <div>
                    <smallbutton  @click="CloseWithYes" toplabel="Close"/>

                </div>
                <div>
                  <label class="responsemessage">{{responseMessage}}</label>
                </div>
            </template>
          </actionmodal>
    </div>
</template>
    
<script>
    import smallbutton from '../controls/button.vue'
    import actionmodal from '../controls/actionmodal.vue'
    import linkbutton from '../controls/linkbutton.vue'
    import removebutton from '../controls/removebutton.vue'
    export default {
        name:'textlist',
        components:{
            smallbutton,
            actionmodal,
            linkbutton,
            removebutton
        },
        props:{
            title:{
                type:String,
                required:true
            },
            disabled:{
              type:Boolean,
              default:false
            },
            buttonclass:{
                type:String,
                default:"button primarybutton"
            },
            textlist:{
                type:Array
            }
        },
        data(){
            return{
                responseMessage:''
            }
        },
        methods:{
            Show(){
                this.$refs.mymodal.openModal()
            },
            async CloseWithYes(){

                this.$refs.mymodal.closeModal()
            },
        
        },
        computed:{
        
            count(){
                return this.textlist.length
            },
        }
        
    }
    </script>
    
    <style>
    .leftmargin{
        margin-left:150px;
    }
    </style>